<template>
  <div>
    <FormulateForm
      class="no-top-margin-all-descendants"
      :values="theFormValues"
      @input="getNewFormValues"
      @validation="formValidationStateChanged">
      <section v-show="createScenarioStep == 0 || submittedSimulationHasErrors">
        <h5 class="mb-3">Geometry</h5>
        <span class="fine-text">Please upload your 3D geometry files below</span>
        <b-card bg-variant="light" class="mb-3 mt-2">
          <b-icon-exclamation-circle /><span class="font-weight-bold ml-2">Important:</span>
          <ul class="fine-text">
            <li>Use .stl format</li>
            <li>No large holes (>0.5m) or missing faces</li>
            <li>Provide models with minimal detail</li>
            <li v-if="simulation && simulation.simulation_type === 'ML'">
              Files should be no larger than 20MB.
            </li>
            <li v-else>
              Files should be no larger than 50MB.
            </li>
          </ul>
          <span class="fine-text">For more information: </span>
          <a href="https://help.orbitalstack.com/preparing-the-geometry-guide/"
            target="_blank"
            class="fine-text"
            >Preparing Geometry</a>
        </b-card>
        <div>
          <b-alert :show="invalidFilename(newFormValues)" variant="danger" class="mt-2">Use unique filenames for each geometry file</b-alert>
          <b-alert :show="uploadInProgress(newFormValues)" variant="warning" style='display: none;'>A file is currently being uploaded</b-alert>
        </div>
        
        <div class="layers-group">
          <b-spinner small v-if="!assetUploadUrlAvailable" class="fileInputSpinner" />
          <div v-for="inputFile in inputFiles" :key="inputFile.key">
            <div v-if="(inputFile.show_for_cladding && simulationIsCladding) ||
                (!simulationIsCladding && inputFile.show_for_ml && simulation && simulation.simulation_type === 'ML') ||
                (simulation && simulation.simulation_type === 'CFD') || (simulation && simulation.simulation_type === 'IMPORT' && inputFile.show_for_import)">
              <hr class="mt-2" />
              <h6>{{inputFile.name}}</h6>
              <label class='fine-text font-weight-light'>{{inputFile.helpText}}</label>
              <div v-if="newFormValues[`${inputFile.key}-file`]">
                <div v-for='file in newFormValues[`${inputFile.key}-file`].files'
                  :key='file.uuid'
                  :class="{ 'error-file': filesToFix.includes(file.name) }"
                  class='bg-white p-2 my-2' >
                  <div class='py-2' >
                    <b-icon v-if='canAddMetaData && file.file.uploadComplete'
                      :icon='file.showMetaData ? "caret-down-fill" : "caret-right-fill"'
                      role='button'
                      @click='toggleFileMetaData(file)'>
                    </b-icon>
                    {{file.name}}
                    <b-icon icon="exclamation-triangle-fill" variant="danger" v-if="file.file.hasError"></b-icon>
                    <b-icon v-if="file.file.uploadComplete || file.file.hasError"
                      icon='x'
                      class='float-right'
                      role='button'
                      @click='removeFile(`${inputFile.key}-file`, file, newFormValues[`${inputFile.key}-file`].files)'>
                    </b-icon>
                    <b-spinner v-else class='float-right' small ></b-spinner>
                  </div>
                  <div v-if='file.showMetaData'>
                    <FormulateInput
                      v-if="['study', 'surrounds'].includes(inputFile.key)"
                      v-model='file.file.metaData.refinementLevel'
                      @change='saveMetaData'
                      :options="getGlobalTemplatesForParamType('Refinement Level')"
                      type='select'
                      placeholder='Select an option'
                      label='Refinement Level'
                      class='mb-1'/>
                    <FormulateInput
                      v-if="['study', 'surrounds'].includes(inputFile.key)"
                      v-model='file.file.metaData.geometryGeneration'
                      @change='(e) => saveGeometryGeneration(file, e.target.value)'
                      :options="['existing', 'proposed', 'future']"
                      type='select'
                      placeholder='Select an option'
                      label='Geometry Generation'/>
                    <!-- FormulateInput
                      v-if="'ground' === inputFile.key"
                      v-model='file.file.metaData.material'
                      @change='saveMetaData'
                      :options="getGlobalTemplatesForParamType('Roughness')"
                      type='select'
                      placeholder='Select an option'
                      label='Roughness'
                      class='mb-1' -->
                    <FormulateInput
                      v-if="'presentation_plane' === inputFile.key"
                      v-model='file.file.metaData.elevation'
                      @change='saveMetaData'
                      :options="getGlobalTemplatesForParamType('Elevation')"
                      type='select'
                      placeholder='Select an option'
                      label='Elevation'
                      class='mb-1'/>
                    <FormulateInput
                      v-if="'landscaping' === inputFile.key"
                      v-model='file.file.metaData.material'
                      @change='saveMetaData'
                      :options="getGlobalTemplatesForParamType('Tree Type')"
                      type='select'
                      placeholder='Select an option'
                      label='Tree Type'
                      class='mb-1'/>
                    <FormulateInput
                      v-if="'mitigations' === inputFile.key"
                      v-model='file.file.metaData.material'
                      @change='saveMetaData'
                      :options="getGlobalTemplatesForParamType('Material')"
                      type='select'
                      placeholder='Select an option'
                      label='Material'
                      class='mb-1'/>
                    <FormulateInput
                      v-if="file.file.metaData.material === 'Custom'"
                      v-model='file.file.metaData.customJSON'
                      @change='saveMetaData'
                      type='textarea'
                      label='Custom JSON'
                      class='mb-1'/>
                  </div>
                </div>
              </div>
              <FormulateInput
                accept=".stl"
                type="file"
                @input="setAcceptAttribute"
                :name="`${inputFile.key}-file`"
                :disabled='selectedProject.is_demo_project || !assetUploadUrlAvailable'
                :validation="inputFile.validation"
                :validation-rules="{ validateMaxFileSize, validateFilename, validateFileType }"
                :validation-messages="{
                  validateMaxFileSize: `Max file size is: ${maxFileSize / 1048576}MB`,
                  validateFilename: 'Filename cannot start with a number',
                  validateFileType: 'File must be of type stl'
                }"
                multiple
                element-class="mt-0"
                class='mb-0  hide-input-geometry-files'
                @file-upload-complete="(e) => fileUploaded(`${inputFile.key}-file`, e)"
                @file-removed="(e) => fileRemoved(`${inputFile.key}-file`, e)"
                :uploader="customUploader"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="layers-section" v-show="createScenarioStep != 0">
        <section class="layers-section" v-if="isViewerMode">
          <div
            class="d-flex justify-content-between"
            v-if="createScenarioStep == 1">
            <h5 class="mb-2">Review Geometry Integrity</h5>
          </div>
          <p class="fine-text" v-if="createScenarioStep == 1">
            Before submitting your scenario, we ask that you perform a few checks
            on your model to ensure a successful simulation.
            <b>Please click on each icon below to confirm that your geometry is valid:</b>
          </p>
          <div class="row" v-if="createScenarioStep == 1 && !simulationIsCladding">
            <img class="geo-file-step" v-if="!geometryFileValidationSteps || !geometryFileValidationSteps.presentation_surfaces"
              src="~@/assets/img/Presentation-surfaces-unchecked.png"
              alt="Presentation Surfaces"
              height="100"
              @click="toggleGeometryStep('Presentation-Surfaces')"
            />
            <img class="geo-file-step" v-else
              src="~@/assets/img/Presentation-surfaces-checked.png"
              alt="Presentation Surfaces"
              height="100"
              @click="toggleGeometryStep('Presentation-Surfaces')"
            />
            <div class="wrapper col-6">
              <h5 class="mt-1">Presentation Surfaces</h5>
              <p class="fine-text">
                All presentation surfaces should be elevated 1.5m (5ft) above the
                ground or elevated terrace.
              </p>
            </div>
          </div>
          <div class="row" v-if="createScenarioStep == 1">
            <img class="geo-file-step" v-if="!geometryFileValidationSteps || !geometryFileValidationSteps.project_origin"
              src="~@/assets/img/Project-Origin-unchecked.png"
              alt="Project Origin"
              height="100"
              @click="toggleGeometryStep('Project-Origin')"
            />
            <img class="geo-file-step" v-else
              src="~@/assets/img/Project-Origin-checked.png"
              alt="Project Origin"
              height="100"
              @click="toggleGeometryStep('Project-Origin')"
            />
            <div class="wrapper col-6">
              <h5 class="mt-1">Project Origin</h5>
              <p class="fine-text">
                Ensure the origin of the model is within the study geometry
                (preferably towards the middle) and aligned with previous
                scenarios
              </p>
            </div>
          </div>
          <div class="row" v-if="createScenarioStep == 1">
            <img class="geo-file-step" v-if="!geometryFileValidationSteps || !geometryFileValidationSteps.north_orientation"
              src="~@/assets/img/North-Orientation-unchecked.png"
              alt="North Orientation"
              height="100"
              @click="toggleGeometryStep('North-Orientation')"
            />
            <img class="geo-file-step" v-else
              src="~@/assets/img/North-Orientation-checked.png"
              alt="North Orientation"
              height="100"
              @click="toggleGeometryStep('North-Orientation')"
            />
            <div class="wrapper col-6">
              <h5 class="mt-1">North Orientation</h5>
              <p class="fine-text">
                Ensure that your model is oriented correctly with Y+ of the model
                pointing to true north.
              </p>
            </div>
          </div>
          <div class="row" v-if="createScenarioStep == 1">
            <img class="geo-file-step" v-if="!geometryFileValidationSteps || !geometryFileValidationSteps.model_scale"
              src="~@/assets/img/model-scale-unchecked.png"
              alt="Model Scale"
              height="100"
              @click="toggleGeometryStep('Model-Scale')"
            />
            <img class="geo-file-step" v-else
              src="~@/assets/img/model-scale-checked.png"
              alt="Model Scale"
              height="100"
              @click="toggleGeometryStep('Model-Scale')"
            />
            <div class="wrapper col-6">
              <h5 class="mt-1">Model Scale</h5>
              <p class="fine-text">
                Ensure the model is scaled 1:1metres. When loaded, you should see
                entire buildings filling the screen
              </p>
            </div>
          </div>
          <div class="row" v-if="createScenarioStep == 1">
            <img class="geo-file-step" v-if="!geometryFileValidationSteps || !geometryFileValidationSteps.terrain"
              src="~@/assets/img/Terrain-unchecked.png"
              alt="Ground/Terrain"
              height="100"
              @click="toggleGeometryStep('Terrain')"
            />
            <img class="geo-file-step" v-else
              src="~@/assets/img/Terrain-checked.png"
              alt="Ground/Terrain"
              height="100"
              @click="toggleGeometryStep('Terrain')"
            />
            <div class="wrapper col-6">
              <h5 class="mt-1">Ground/Terrain</h5>
              <p class="fine-text">
                The ground should NOT extend more than 300m beyond your study
                building(s). There should be ground under all study and surround
                buildings.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-between" v-else v-show="!isJobTypeImport">
            <h5 class="mb-2">Select Simulation Parameters</h5>
          </div>
          <p class="fine-text" v-if="createScenarioStep == 2">
            Specify the type of simulations included in this scenario and the
            related configuration options
          </p>
        </section>
      </section>
    </FormulateForm>
    <b-toast id="save-changes-notice" toast-class="save-changes-toast-style no-top-margin-all-descendants" variant="warning" toaster='b-toaster-bottom-right' title="You have unsaved changes" :no-close-button=true :no-auto-hide=true>
      <template #default>
        <div v-if='scenarioSaveInProgress' class="d-flex flex-row align-items-center justify-content-center">
          <b-spinner class="mr-2" small/>
          <span>Saving...</span>
        </div>
        <div v-else class="d-flex flex-row align-items-center">
          <b-button variant="primary" class="small mr-2" @click="saveChanges">Save</b-button>
          <span>or click the 'Next' button below to save and move to the next step</span>
        </div>
      </template>
    </b-toast>
    <b-modal centered hide-header-close title="Duplicate Filename Detected" v-model="showDuplicateFilenameModal">
      <p class="text-danger">
        Error: duplicate filenames not permitted.
      </p>
      <template #modal-footer>
        <div class="float-right">
          <b-button @click="closeDuplicateFilenameModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Asset } from '@/models/asset';
import { LayerSet } from '@/models/layer-set';
import { ContainerClient } from '@azure/storage-blob';
import { EventBus } from '@/network/eventbus';
export default {
  name: 'FileUploader',
  data() {
    return {
      newFormValues: this.formValues,
      geometryFileValidationSteps: null,
      currentlyUploadedFiles: [],
      showDuplicateFilenameModal: false
    };
  },
  mounted() {
    this.$store.dispatch('project/getProjectAssetUploadUrlForProjectAssets', this.projectId);
  },
  async created() {
    await this.getGlobalTemplates();

    if (this.projectId && this.studyId && this.configurationId) {
      await this.storeGetGeometryValidationSteps({
        projectId: this.projectId, // From prop
        studyId: this.studyId, // From prop
        configurationId: this.configurationId, // From prop
      });
    }

    if (this.geometryValidationSteps === undefined && !this.loggedInUser.is_otp_user) {
      //Create new geometry step validation
      await this.storeCreateGeometryValidationSteps({
        projectId: this.projectId, // From prop
        studyId: this.studyId, // From prop
        configurationId: this.configurationId, // From prop
      });

      if (this.geometryValidationSteps === undefined) {
        //Create new geometry step validation
        await this.storeCreateGeometryValidationSteps({
          projectId: this.projectId, // From prop
          studyId: this.studyId, // From prop
          configurationId: this.configurationId, // From prop
        });
      }

      this.geometryFileValidationSteps = this.geometryValidationSteps;
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters['project/loading'];
    },
    projectAssetsPath(){
      return `configurations/${this.configurationId}/simulation/${this.simulation.id}/assets`;
    },
    projectAssetsUrl(){
      return `${this.$store.getters['project/projectAssetsContainerUrl']}${this.projectAssetsPath}?${this.$store.getters['project/projectAssetsSasToken']}`;
    },
    simulationType() {
      return this.simulation?.simulation_type;
    },
    isJobTypeImport() {
      let jobType;
      this.simulation?.job_types_with_criteria?.some(x => {
        jobType =  this.jobTypes?.find(z => z.id === x.job_type);        
      });  
      if(jobType?.label == 'Imports GLTF Results')
        return true;
      return false;
    },
    theFormValues: {
      get() {
        return this.formValues;
      },
      set(newFormValues) {
        return newFormValues;
      }
    },
    inputFiles() {
      return [
        { key: 'study', name: 'Study building', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: true, show_for_cladding: true, show_for_import: true, helpText: 'Origin 0,0,0 (in repeatable locations) within the study building; True north is oriented to Y+; scale(1:1) meters; Minimal detail (no furniture or interior walls)' },
        { key: 'surrounds', name: 'Surrounds/Context', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: true, show_for_cladding: true, show_for_import: true, helpText: 'Must be < 250m-300m from Study Building; Provide a minimal detailed model' },
        { key: 'ground', name: 'Ground surface', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: true, show_for_cladding: true, show_for_import: true, helpText: 'Simplified topography or important grading, otherwise flat' },
        { key: 'presentation_plane', name: 'Presentation plane', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: true, show_for_cladding: false, show_for_import: false, helpText: 'These surfaces must be raised by 1.5m above the location for which the results are desired' },
        { key: 'landscaping', name: 'Landscaping', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: false, show_for_cladding: false, show_for_import: true, helpText: 'Please specify porosity in Scenario Notes section of the upcoming simulation parameter selection step' },
        { key: 'mitigations', name: 'Mitigations', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: false, show_for_cladding: false, show_for_import: true, helpText: 'Please specify porosity in Scenario Notes section of the upcoming simulation parameter selection step' },
        { key: 'overlay', name: 'Overlay', validation: 'optional|validateMaxFileSize|validateFilename|validateFileType', show_for_ml: this.canLoadOverlay, show_for_cladding: this.canLoadOverlay, show_for_import: true, helpText: 'Street names, objects or diverse. For visualization only, does not influence simulations' }
      ];
    },
    canLoadOverlay() {
      return this.hasModuleByName('overlays');
    },
    submittedSimulationHasErrors() {
      return this.simulation?.errors?.length > 0 && this.simulation.category === 'SUBMITTED';
    },
    maxFileSize() {
      return this.simulation?.simulation_type === 'ML' ? 20971520 : 104857600;
    },
    preExistingAssetFiles() {
      let inboundSimulation = this.uploadedScenario?.simulations?.find(simulation => simulation.category == 'INBOUND');
      return inboundSimulation?.assets;
    },
    canAddMetaData() {
      return this.hasModuleByName('file meta data');
    },
    simulationIsCladding() {
      let jobType;
      return this.simulation?.job_types_with_criteria?.some(x => {
        jobType = this.jobTypes?.find(z => z.id === x.job_type);
        return jobType?.sub_engine === 'Cladding';
      });
    },
    ...mapGetters('project/simulationAsset', ['simulation', 'globalTemplates', 'geometryValidationSteps']),
    ...mapGetters(['userCompany', 'loggedInUser', 'companySubscriptions']),
    ...mapGetters('project', ['isViewerMode', 'createScenarioStep', 'viewerMode', 'selectedProject', 'uploadedScenario', 'jobTypes', 'filesToFix', 'assetUploadUrlAvailable', 'scenarioSaveInProgress'])
  },
  props: {
    formValues: {
      type: Object,
      required: false
    },
    configurationId: {
      type: Number,
      required: false
    },
    studyId: {
      type: Number,
      required: false
    },
    simulationId: {
      type: Number,
      required: false
    },
    projectId: {
      type: Number,
      required: false
    },
    geometryLayers: {
      required: true
    }
  },
  watch: {
    async createScenarioStep(newValue, oldValue) {
      if (oldValue == 0 && newValue == 1) {
        this.$bvToast.hide('save-changes-notice');
        this.readyFiles = this.prepareFilesForUpdate();
        await this.updateScenario();
      }
    }
  },
  methods: {
    closeDuplicateFilenameModal() {
      this.showDuplicateFilenameModal = false;
    },
    async saveChanges() {
      this.readyFiles = this.prepareFilesForUpdate();
      let saveResp = await this.updateScenario();
      if (saveResp == null) {
        //error occurred saving changes emit another toast
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to save, please refresh and try again'
        });
      } else {
        //save succeeded, hide the "you have unsaved changes" toast
        this.$bvToast.hide('save-changes-notice');
      }
    },
    getBlobConfigInfo(filename){
      let url = `${this.$store.getters['project/projectAssetsContainerUrl']}${this.projectAssetsPath}/${filename}?${this.$store.getters['project/projectAssetsSasToken']}`;
      return {
        path: `${this.projectAssetsPath}/${filename}`,
        url: url
      };
    },
    async customUploader(file, progress, error) {
      try {
        const containerClient = new ContainerClient(this.projectAssetsUrl);
        const filename = `${file.name}`;
        const blobBlockClient = containerClient.getBlockBlobClient(file.name);  

        blobBlockClient.upload(file, file.size).then(
          () => {
            progress(100);
          },
          (error) => {
            error(`Unable to upload ${file.name}`);
            console.error(error);
          }
        );
        let blobInfo = this.getBlobConfigInfo(file.name);
        this.fileBeingUploaded = null;
        return Promise.resolve({
          file: filename,
          path: blobInfo.path,
          url: blobInfo.url,
          name: filename
        });
      } catch (ex) {
        this.fileBeingUploaded = null;
        error(`Unable to upload ${file.name}`);
        console.error(ex);
      }
    },
    hasModuleByName(moduleName) {
      let companyHasModule = !!this.userCompany?.modules?.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
      
      let userSubscriptionHasModule = false;
      if (this.loggedInUser?.subscription != null) {
        let user_subscription = this.companySubscriptions.find(x => x.id == this.loggedInUser.subscription);
        if (user_subscription) {
          userSubscriptionHasModule = !!user_subscription.subscription_type.modules.find(x => x.name.toLowerCase() === moduleName.toLowerCase());
        }
      }
      
      return companyHasModule || userSubscriptionHasModule;
    },
    getGlobalTemplatesForParamType(parameterType) {
      return this.globalTemplates.filter(gt => gt.parameter_type === parameterType && gt.enabled == true).map(gt => gt.name.toLowerCase());
    },
    formValidationStateChanged(validationState) {
      this.storeformErrors( { 'component': 'FileUpload', 'hasErrors': validationState.hasErrors });
    },
    getGeometryGeneration(fileName) {
      //Note:  this is only used when rendering files that were just dropped into the viewer.  The rest of the time the assets are loaded from the backend and their geometry
      //generation is determined by backend code (which does the same thing)
      const geometry_generation_types = ['existing', 'proposed', 'future', 'v1', 'v2'];
      for (let generation of geometry_generation_types) {
        if (fileName.toLowerCase().includes(generation)) {
          return generation;
        }
      }
    },
    async fileUploaded(input, uploadedFile) {
      // don't render already uploaded files (duplicate filenames)
      let fileIsAlreadyUploaded = this.currentlyUploadedFiles.find(name => name === uploadedFile.path.name);
      this.currentlyUploadedFiles.push(uploadedFile.path.name);
      if(fileIsAlreadyUploaded) {
        this.showDuplicateFilenameModal = true;
        this.removeFile(input, uploadedFile, this.newFormValues[input].files);
      } else {
      
        let geometryType = this.getGeometryTypeByNewFilesKey(input);
        this.initializeMetaData(input, uploadedFile);
        let assetToRender = new Asset({
          id: `${geometryType} - ${uploadedFile.path.url}`,
          asset_file: uploadedFile.path.url,
          filename: uploadedFile.path.name,
          geometry_type: geometryType,
          geometry_generation: uploadedFile.file.metaData.geometryGeneration,
          layer_type: 'Geometry',
          remeshed_assets: []
        });
        let layerSetToRender = new LayerSet(assetToRender.simulationResult.geometry_type, [assetToRender]);
        uploadedFile.file.asset = assetToRender;
        this.geometryFileUploaded(assetToRender);
        this.readyFiles = this.prepareFilesForUpdate();
        this.addSimulationAsset({configurationId: this.configurationId, asset: assetToRender});
        this.$emit('file-uploaded', layerSetToRender);
        uploadedFile.file.uploadComplete = true;
        if (this.canAddMetaData) {
          uploadedFile.showMetaData = this.simulationType === 'CFD';
        }
        this.$forceUpdate();
        if(this.submittedSimulationHasErrors) {
          await this.updateScenario();
        } else {
          this.$bvToast.show('save-changes-notice');
        }
      }
    },
    initializeMetaData(input, file) {
      file.file.metaData = {};
      let filename = file.name.toLowerCase();
      if(['study-file', 'surrounds-file'].includes(input)) {
        if(filename.includes('existing')) {
          file.file.metaData.geometryGeneration = 'existing';
        } else if(filename.includes('proposed')) {
          file.file.metaData.geometryGeneration = 'proposed';
        } else if(filename.includes('future')) {
          file.file.metaData.geometryGeneration = 'future';
        } else if(filename.includes('v1')) {
          file.file.metaData.geometryGeneration = 'v1';
        } else if(filename.includes('v2')) {
          file.file.metaData.geometryGeneration = 'v2';
        }
        if(filename.includes('balcony') 
        || filename.includes('balconies')
        || filename.includes('parapet')
        || filename.includes('balustrade')
        || filename.includes('fin')
        || filename.includes('screen')
        || filename.includes('canopy')
        || filename.includes('canopies')
        || filename.includes('pergola')
        || filename.includes('railing')) {
          file.file.metaData.refinementLevel = 'refined';
        }
      } else if(input === 'presentation_plane-file') {
        if(filename.includes('balcony')) {
          file.file.metaData.elevation = 'above grade';
        } else if(filename.includes('balconies')) {
          file.file.metaData.elevation = 'above grade';
        } else if(filename.includes('terrace')) {
          file.file.metaData.elevation = 'above grade';
        } else if(filename.includes('podium')) {
          file.file.metaData.elevation = 'above grade';
        }else if(filename.includes('above grade')) {
          file.file.metaData.elevation = 'above grade';
        }
      }
    },
    validateMaxFileSize(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          if (file.file.size > this.maxFileSize) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }

      return true;
    },
    validateFilename(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          if (file.name.match(/^\d/) ) {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }

      return true;
    },
    validateFileType(context) {
      if (context?.value?.files) {
        for (const file of context.value.files) {
          if (file.name.match(new RegExp('[^.]+$'))[0] !== 'stl' && file.name.match(new RegExp('[^.]+$'))[0] !== 'STL') {
            file.file['hasError'] = true;
            this.$forceUpdate();
            return false;
          }
        }
      }

      return true;
    },
    // Vue Formulate doesn't pass on the 'accept' attribute from the FormulateInput on to the new input element it creates that allows for multiple files, therefore
    // When a file is dropped on the control, wait for the UI to refresh (adding the new control), then select it and append the 'accept' attribute
    async setAcceptAttribute() {
      await this.$nextTick();
      const multiFileInputConrols = document.getElementsByClassName('formulate-file-add-input');

      for (let element of multiFileInputConrols) {
        element.setAttribute('accept', '.stl');
      }
    },
    async getNewFormValues(data) {
      this.newFormValues = data;
    },
    getGeometryTypeByNewFilesKey(key) {
      if (key === 'study-file') {
        return 'study';
      }
      if (key === 'surrounds-file') {
        return 'surround';
      }
      if (key === 'ground-file') {
        return 'ground';
      }
      if (key === 'presentation_plane-file') {
        return 'presentation plane';
      }
      if (key === 'landscaping-file') {
        return 'landscaping';
      }
      if (key === 'mitigations-file') {
        return 'mitigation';
      }
      if (key === 'overlay-file') {
        return 'overlay';
      }
      return null;
    },
    removeFile(input, fileToRemove, allFiles) {
      let remainingFiles = allFiles.filter(file => fileToRemove !== file);

      //the Vue formulate files control does not handle remove events correctly so the below code (which is kind of hacky) clears out the deleted files from the underlying controls manually
      this.newFormValues[input].files = remainingFiles;  //setting the 'files' array will remove the file visibly from the control
      this.newFormValues[input].results = remainingFiles;  //resetting the 'results' array seems to do nothing, though it contains a list of uploaded files so to keep things clean it's being maintained

      //after removing the file from the above the control appears ok but there's a bug where you cannot re-add the file that you just deleted.  To solve this the file also needs to
      //be removed from the html input element's fileList property, which is done via a DataTransfer object
      var dt = new DataTransfer();
      for (let i=0; i< remainingFiles.length; i++) {
        //create a new File object from each remaining file to make sure we don't get a "file is not of type 'File'" error.  the contents don't matter, just the filename
        dt.items.add(new File([], remainingFiles[i].name));
      }

      this.newFormValues[input].fileList = dt.files;  //setting the fileList on the newFormValues[input] doesn't fix the bug, but similar to the 'result' array we're doing it for cleanliness

      //to actually fix the bug and let the same file be re-added after it's removed we need to target the <input> element, which done by inferring the id that VueFormulate will assign to it
      if(document.getElementsByName(input)) {
        document.getElementsByName(input)[0].files = dt.files;
      }

      let fileDuplicates = this.currentlyUploadedFiles.filter(filename => filename === fileToRemove.path.name).length > 1;
      let fileIndex = this.currentlyUploadedFiles.indexOf(fileToRemove.path.name);
      if(fileIndex != -1) this.currentlyUploadedFiles.splice(fileIndex, 1);
      if (fileDuplicates) return;

      this.fileRemoved(input, remainingFiles);  //this will un-render the file from the viewer
    },
    fileRemoved(input, remainingFiles) {
      let layerIdentifier = this.getGeometryTypeByNewFilesKey(input);
      let loadedFileNamesForType = this.geometryLayers
        .filter((layer) => layer.identifier.includes(layerIdentifier))  //includes() instead of === is used here to deal with geometry files that have a generation.  For these, the identifier isn't "study", it's "study = future" (as an example)
        .map((layer) => layer.layerSetAssets[0].simulationResult.filename);

      let remainingFilenames = remainingFiles.map(file => file.name); // Takes identical files into account, verify differences on name
      //find the difference between the 2 arrays to find the file that was removed
      let difference = loadedFileNamesForType.filter(
        (x) => !remainingFilenames.includes(x)
      );
      let removedFile = difference[0];
      let matchingLayer = this.geometryLayers
        .filter((layer) => layer.identifier.includes(layerIdentifier)) //includes() instead of === is used here to deal with ggeometry files that have a generation. For these, the identifier isn't "study", it's "study = future" (as an example)
        .filter(
          (layer) =>
            layer.layerSetAssets[0].simulationResult.filename === removedFile
        )[0];

      if (matchingLayer) {
        this.$emit('file-removed', matchingLayer);
        this.$bvToast.show('save-changes-notice');  
      }
      this.readyFiles = this.prepareFilesForUpdate();
      this.removeSimulationAsset({configurationId: this.configurationId, assetId: matchingLayer?.layerSetAssets[0].simulationResult.id});
      let geometryType = this.getGeometryTypeByNewFilesKey(input);
      const index = this.filesToFix.indexOf(removedFile);
      this.filesToFix.splice(index, 1);
      this.setFilesToFix(this.filesToFix);
      this.geometryFileRemoved({'geometryType': geometryType, 'filename': removedFile});
      if(this.submittedSimulationHasErrors) this.updateScenario();
    },
    cleanJSON(data) {
      if (data === undefined) {
        return [];
      }

      for (var i = 0; i < data.length; i++) {
        if (data[i].path === undefined) {
          data[i].path = data[i].url;
        }
      }

      return JSON.parse(JSON.stringify(data));
    },
    prepareFilesForUpdate() {
      let response = {
        'study-file': this.newFormValues['study-file'] ? this.cleanJSON(this.newFormValues['study-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id }; })) : [],
        'surrounds-file': this.newFormValues['surrounds-file'] ? this.cleanJSON(this.newFormValues['surrounds-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
        'ground-file': this.newFormValues['ground-file'] ? this.cleanJSON(this.newFormValues['ground-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
        'overlay-file': this.newFormValues['overlay-file'] ? this.cleanJSON(this.newFormValues['overlay-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
        'presentation_plane-file': this.newFormValues['presentation_plane-file'] ? this.cleanJSON(this.newFormValues['presentation_plane-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
        'landscaping-file': this.newFormValues['landscaping-file'] ? this.cleanJSON(this.newFormValues['landscaping-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
        'mitigations-file': this.newFormValues['mitigations-file'] ? this.cleanJSON(this.newFormValues['mitigations-file'].files?.map(a => { return { ...a.path, metaData: a.file.metaData, asset_id: a.file.asset_id  }; })) : [],
      };
      return response;
    },
    async updateScenario() {
      if(this.selectedProject.is_demo_project) return null;
      try {
        let resp = await this.storeUpdateScenario({
          projectId: this.projectId, // From prop
          studyId: this.studyId, // From prop
          configurationId: this.configurationId, // From prop
          scenario: this.createScenarioFromFormValues(),
          formValues: this.readyFiles,
        });

        //copy asset Ids from save call back into form so subsequent saves don't recreate assets
        for (let saved_asset of resp.simulations[0].assets) {
          let key;
          if (saved_asset.geometry_type == 'surround') {
            key = 'surrounds-file';
          } else if (saved_asset.geometry_type == 'mitigation') {
            key = 'mitigations-file';
          } else if (saved_asset.geometry_type == 'presentation plane') {
            key = 'presentation_plane-file';
          } else {
            key = `${saved_asset.geometry_type}-file`;
          }
          

          for (let filelistentry of this.newFormValues[key].fileList) {
            if (filelistentry.name == saved_asset.filename && filelistentry.asset.simulationResult.geometry_type == saved_asset.geometry_type) {
              filelistentry.asset_id = saved_asset.id;
            }
          }
        }
        
        return resp;
      } catch (error) {
        return null;
      }
    },
    createScenarioFromFormValues() {
      return {
        name: this.simulation.label,
        simulation_type: this.simulation?.simulation_type,
        geometry_file_urls: this.geometryUrlsFromFormValues(),
        simulation_id: this.simulation.id,
        geometryValidationSteps: this.geometryValidationSteps,
        pre_existing_asset_files: this.preExistingAssetFiles
      };
    },
    geometryUrlsFromFormValues() {
      if (!this.readyFiles) {
        return null;
      }

      let files = Object.keys(this.readyFiles)
        .filter((key) => key.includes('-file'))
        .map((keyName) => {
          return { [keyName.split('-file')[0]]: this.readyFiles[keyName] };
        })
        .reduce((object, item) => {
          return { ...object, ...item };
        }, {});

      return files;
    },
    allGeometryFiles(formValues) {
      const allGeometryFiles = [];
      Object
        .keys(formValues)
        .filter(key => key.includes('-file'))
        .forEach(key => {
          for (const [,value] of Object.entries(formValues[key]?.files || {})) {
            allGeometryFiles.push(value);
          }
        });
      return allGeometryFiles;
    },
    invalidFilename(formValues) {
      if (!formValues) {
        this.storeInvalidFilename(false);
        return false;
      }
      const allGeometryFiles = this.allGeometryFiles(formValues);
      const allFileNames = [];

      for (const file of allGeometryFiles ) {
        allFileNames.push(file.name);
      }

      return this.hasDuplicates(allFileNames);
    },
    hasDuplicates(array) {
      var valuesSoFar = Object.create(null);
      for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
          this.storeInvalidFilename(true);
          return true;
        }
        valuesSoFar[value] = true;
      }
      this.storeInvalidFilename(false);
      return false;
    },
    toggleGeometryStep(step) {
      if (this.geometryValidationSteps) {
        switch(step) {
        case 'Presentation-Surfaces':
          this.geometryFileValidationSteps.presentation_surfaces = !this.geometryFileValidationSteps.presentation_surfaces;
          break;
        case 'Project-Origin':
          this.geometryFileValidationSteps.project_origin = !this.geometryFileValidationSteps.project_origin;
          break;
        case 'North-Orientation':
          this.geometryFileValidationSteps.north_orientation = !this.geometryFileValidationSteps.north_orientation;
          break;
        case 'Model-Scale':
          this.geometryFileValidationSteps.model_scale = !this.geometryFileValidationSteps.model_scale;
          break;
        default:
          this.geometryFileValidationSteps.terrain = !this.geometryFileValidationSteps.terrain;
        }

        this.updateGeometryValidationSteps();
      }
    },
    async updateGeometryValidationSteps() {
      try {
        if (this.projectId && this.studyId && this.configurationId) {
          await this.storeUpdateGeometryValidationSteps({
            projectId: this.projectId, // From prop
            studyId: this.studyId, // From prop
            configurationId: this.configurationId, // From prop
            geometryValidationStepsId: this.geometryFileValidationSteps.id,
            geometryValidationSteps: this.geometryFileValidationSteps,
          });
        }
        return {};
      } catch (error) {
        return null;
      }
    },
    uploadInProgress(formValues) {
      if (!formValues) {
        this.storeFormUploading(false);
        return false;
      }
      const allGeometryFiles = this.allGeometryFiles(formValues);

      for (let file of allGeometryFiles) {
        if (file.file.uploadComplete !== true) {
          this.storeFormUploading(true);
          return true;
        }
      }

      this.storeFormUploading(false);
      return false;
    },
    toggleFileMetaData(file) {
      file.showMetaData = !file.showMetaData;
      this.$forceUpdate();
    },
    saveMetaData() {
      this.readyFiles = this.prepareFilesForUpdate();
      this.updateScenario();
    },
    saveGeometryGeneration(fileToUpdate, theme) {
      this.readyFiles = this.prepareFilesForUpdate();
      this.updateScenario();
      this.geometryChangeTheme({
        asset: fileToUpdate.file.asset,
        theme: theme
      });
    },
    ...mapActions({
      geometryFileUploaded: 'project/viewer/geometryFileUploaded', //Used to notify the store (and other components) that there are geometry files that have been uploaded (i.e. dropped on the upload control)
      storeUpdateScenario: 'project/updateScenario', //Used to update scenario
      storeUpdateGeometryValidationSteps: 'project/simulationAsset/updateGeometryValidationSteps',
      storeGetGeometryValidationSteps: 'project/simulationAsset/getGeometryValidationSteps',
      storeCreateGeometryValidationSteps: 'project/simulationAsset/createGeometryValidationSteps',
      addSimulationAsset: 'project/simulationAsset/addSimulationAsset',
      getGlobalTemplates: 'project/simulationAsset/getGlobalTemplates',
      removeSimulationAsset: 'project/simulationAsset/removeSimulationAsset',
      storeformErrors: 'project/setformErrors', //Used to update the simulation parameters flags
      storeInvalidFilename: 'project/setInvalidFilename', //Used to update the simulation parameters flags
      storeFormUploading: 'project/setFormUploading', // used when a file is uploading
      geometryFileRemoved: 'project/viewer/geometryFileRemoved',
      geometryChangeTheme: 'project/viewer/geometryChangeTheme',
      setFilesToFix: 'project/setFilesToFix',
    }),
  }
};
</script>
<style>
  .save-changes-toast-style {
    position: absolute;
    bottom: 7rem;
  }
</style>
<style scoped>

.fileInputSpinner {
  position: relative;
  left:7rem;
}

.wrapper{
  display:flex;
  flex-direction: column;
  padding-left: 0.938rem;
}

.row {
  padding: 1.063rem;
}

.fine-text {
  font-size: 0.75rem;
}

.error-file {
  border-style: solid;
  border-color: #c00;
  border-width: 0.125rem;
}

.layers-panel h5 {
  font-size: 1em;
  font-weight: 700;
  text-transform: none;
  letter-spacing: 0;
}

.geo-file-step:hover {
  cursor: pointer;
}

.hide-input-geometry-files /deep/ .formulate-files li {
  display: none !important;
}


</style>